import React from "react";
import theme from "theme";
import { Theme, Image, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Hizmetlerimiz | Wixnu Oto Yıkama'da Kusursuz Hizmetlerimiz
			</title>
			<meta name={"description"} content={"Işıltının tadını çıkarın - Wixnu'da araç bakımının zirvesini yaşayın"} />
			<meta property={"og:title"} content={"Hizmetlerimiz | Wixnu Oto Yıkama'da Kusursuz Hizmetlerimiz"} />
			<meta property={"og:description"} content={"Işıltının tadını çıkarın - Wixnu'da araç bakımının zirvesini yaşayın"} />
			<meta property={"og:image"} content={"https://wixnu.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://wixnu.com/img/4632577.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://wixnu.com/img/4632577.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://wixnu.com/img/4632577.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://wixnu.com/img/4632577.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://wixnu.com/img/4632577.png"} />
			<meta name={"msapplication-TileImage"} content={"https://wixnu.com/img/4632577.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" background="--color-light" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://wixnu.com/img/4.jpg"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Hizmetlerimiz
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Wixnu Carwash, çevre dostu temizlik çözümlerini detaylara gösterilen titizlikle birleştirerek aracınızın bakımının her yönüne hitap edecek şekilde tasarlanmış kapsamlı bir hizmet paketi sunar. Oto yıkama sofistikasyonunda gelinen son noktayı keşfedin ve aracınızın temizliğini ve parlaklığını yeniden tanımlamamıza izin verin.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="88px 0 88px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-light">
			<Override slot="SectionContent" max-width="1220px" />
			<Box margin="0px 0px 10px 0px" padding="15px 15px 15px 15px" md-margin="0px 0px 40px 0px" lg-margin="0px 0px 56px 0px">
				<Text
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Çevreye Duyarlı Temizlik
				</Text>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--darkL2">
						Su Tasarruflu Yıkamalar
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Box
						padding="0px 0px 0px 0px"
						width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2" sm-text-align="center">
							Su Tasarruflu Yıkamalar: Lekesiz bir temizlik sunarken çevresel etkiyi en aza indirmek için gelişmiş su ıslah sistemlerinden yararlanma.
							<br /><br />
							Biyolojik Olarak Parçalanabilen Temizleyiciler: Çevre dostu ürünlerden oluşan seçkimiz aracınız için nazik ancak etkili bir temizlik sağlayarak hem cilasını hem de çevreyi korur.

						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--darkL2">
						Detaylandırma Mükemmelliği
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Box
						padding="0px 0px 0px 0px"
						width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2" sm-text-align="center">
							İç Detaylandırma: Kapsamlı iç detaylandırmamızla temizliğin derinliklerine dalın, aracınızın içini bozulmamış durumuna getirin.
							<br />
							<br />
							Dış Mükemmellik: Elle cilalanmış bir parlaklıktan lastik ve jant detaylandırmaya kadar, dış hizmetlerimiz aracınızın her açıdan çarpıcı görünmesini sağlar.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--darkL2">
						Özel Bakımlar
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Box
						padding="0px 0px 0px 0px"
						width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2" sm-text-align="center">
							Boya Koruma: Birinci sınıf boya koruma hizmetlerimizle aracınızın boyasını dış etkenlerden koruyarak canlı kalmasını, çizilmelere ve UV hasarına karşı korunmasını sağlayın.
							<br /><br />
							Alt Takım Bakımı: Zamanla korozyona neden olabilecek zararlı maddeleri ortadan kaldıran alt takım temizliğimizle aracınızın ömrünü uzatın.

						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 49px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
			>
				<Box
					width="30%"
					padding="48px 15px 15px 15px"
					align-items="flex-start"
					display="flex"
					justify-content="center"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					margin="0px 10% 0px 0px"
					lg-margin="0px 30px 0px 0px"
					md-width="100%"
					sm-padding="24px 15px 15px 15px"
					md-justify-content="flex-start"
					md-margin="0px 0px 0px 0px"
					sm-justify-content="center"
				>
					<Text margin="0px 0px 0px 0px" font="--headline3" color="--darkL2">
						Özel Paketler
					</Text>
				</Box>
				<Box
					width="70%"
					padding="48px 15px 15px 15px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					border-style="solid"
					border-width="2px 0px 0px 0px"
					border-color="--color-lightD2"
					md-width="100%"
					md-border-width="0px"
					sm-align-items="center"
					sm-flex-direction="column"
					sm-justify-content="center"
					sm-padding="25px 15px 15px 15px"
					md-padding="33px 15px 15px 15px"
				>
					<Box
						padding="0px 0px 0px 0px"
						width="100%"
						sm-align-items="center"
						sm-display="flex"
						sm-flex-direction="column"
						sm-justify-content="center"
					>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2" sm-text-align="center">
							Üyelik Planları: Araçları için en iyisini talep edenler için tasarlanmış özel üyelik seçeneklerimizle özel avantajların, indirimlerin ve öncelikli hizmetin keyfini çıkarın.
							<br /><br />
							Mevsime Özel Ürünler: Aracınızı yaz sıcağından kış soğuğuna kadar değişen koşullara hazırlayan mevsime özel bakımlar.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="60px 0 60px 0"
			background="--color-light"
			color="--dark"
			md-padding="60px 0 60px 0"
			quarkly-title="Advantages/Features-27"
		>
			<Override slot="SectionContent" max-width="1220px" md-align-items="center" sm-min-width="280px" />
			<Box border-radius="24px" margin="0px 0px 20px 0px" md-margin="0px 0px 10px 0px" width="80%">
				<Text
					color="--greyD3"
					md-text-align="center"
					margin="0px 0px 0px 0px"
					font="--headline2"
					width="100%"
					letter-spacing="-1px"
					lg-width="100%"
					md-font="--headline3"
				>
					Neden Wixnu?
				</Text>
			</Box>
			<Box lg-flex-wrap="wrap">
				<Text
					margin="20px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					md-text-align="center"
				>
					Wixnu'yu seçmek, geleneksel araç yıkamanın ötesine geçen bir hizmeti tercih etmek anlamına gelir. Kaliteye, çevresel sorumluluğa ve müşteri memnuniyetine olan bağlılığımız bizi diğerlerinden ayırıyor ve seçici araç sahipleri için tercih edilen bir seçenek haline getiriyor.
				</Text>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Aracınızın Hikayesini Bizimle Oluşturun
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Aracınız yolculuğunuzun bir yansımasıdır. Wixnu'da sizi, eşsiz hizmetlerimizle aracınızın hikayesini oluşturmaya davet ediyor, özen ve dikkatinizin gurur verici bir kanıtı olarak kalmasını sağlıyoruz. Aracınızın hikayesinin bir parçası olmamıza, güzelliğini ve uzun ömürlülüğünü artırmamıza izin verin.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});